import styled from "styled-components";

export const Input = ({ label, value, onChange, placeholder }) => (
  <Wrapper>
    <label>
      {label}
      <StyledInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </label>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 4px;
`;

const StyledInput = styled.input`
  border: none;
  padding: 4px;
  padding-left: 8px;
`;
