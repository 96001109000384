import { useEffect, useState } from "react";
import styled from "styled-components";

import { SpaceBetween } from "./SpaceBetween";

export const TimeInput = ({ label, value, onChange }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(7);
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    onChange(toSeconds(hours, minutes, seconds));
  }, [hours, minutes, seconds, onChange]);
  return (
    <Wrapper>
      <SpaceBetween>
        <label>
          Hours:
          <StyledInput
            name="time"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            placeholder="00"
          />{" "}
        </label>
        <label>
          Minutes:
          <StyledInput
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            placeholder="07"
          />
        </label>
        <label>
          Seconds:
          <StyledInput
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            placeholder="20"
          />
        </label>
      </SpaceBetween>
    </Wrapper>
  );
};

const toSeconds = (hours, minutes, seconds) =>
  3600 * hours + 60 * minutes + 1 * seconds;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  padding: 4px;
  padding-left: 8px;
`;
