import styled from "styled-components";

const Component = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 16px;
`;

export const SpaceBetween = ({ direction = "column", children }) => (
  <Component direction={direction}>{children}</Component>
);
