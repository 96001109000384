export const adjustTime = (
  initialTimeInSeconds,
  actualWeight,
  targetWeight,
  q,
  boatShare
) => {
  const factor =
    ((targetWeight + boatShare) / (actualWeight + boatShare)) ** (q / 3);
  return initialTimeInSeconds / factor;
};

export const wattsPerKilo = (
  initialTimeInSeconds,
  actualWeight,
  distance = 500
) => {
  // https://www.concept2.com/indoor-rowers/training/calculators/watts-calculator
  const magicNumber = 2.8;

  const pace = initialTimeInSeconds / distance;

  const watts = magicNumber / pace ** 3;
  console.log({ pace, watts });

  return watts / actualWeight;
};