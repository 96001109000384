import { adjustTime, wattsPerKilo } from "./erg";
import "./App.css";
import { useState } from "react";
import { SpaceBetween } from "./components/SpaceBetween";
import { Input } from "./components/Input";
import { TimeInput } from "./components/TimeInput";
import { Container } from "./components/Container";

function App() {
  const [time, setTime] = useState(440);

  const [rebasedWeight, setRebasedWeight] = useState(80);

  const [distance, setDistance] = useState(2000);
  const [shareOfBoat, setShareOfBoat] = useState(15);
  const [weightInKilograms, setWeightInKilograms] = useState(75);
  const [q, setQ] = useState(1 / 2);

  const fancyTimeFormat = (duration) => {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  };

  const trim = (number) => number.toString().substring(0, 4);

  return (
    <Container className="App">
      <h1>Weight Adjusted Ergs</h1>

      <body>
        <form>
          <SpaceBetween>
            <TimeInput label={"Your time"} onChange={setTime} />
            <Input
              label={"Your weight (kg)"}
              value={weightInKilograms}
              onChange={(e) => setWeightInKilograms(e.target.value)}
              placeholder={70}
            />

            <h2>Optional Factors</h2>

            <Input
              label="Distance (m)"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              placeholder={2000}
            />
            <Input
              label={"Rebased Weight (kg)"}
              value={rebasedWeight}
              onChange={(e) => setRebasedWeight(e.target.value)}
              placeholder={75}
            />
            <Input
              label={"Boat Share (kg)"}
              value={shareOfBoat}
              onChange={(e) => setShareOfBoat(e.target.value)}
              placeholder={15}
            />

            <Input
              label={"Q Factor"}
              value={q}
              onChange={(e) => setQ(e.target.value)}
              placeholder="q factor - between 1/2 - 2/3"
            ></Input>
          </SpaceBetween>
        </form>

        <h1>
          Adjusted Score:{" "}
          {fancyTimeFormat(
            adjustTime(time, weightInKilograms, rebasedWeight, q, shareOfBoat)
          )}
        </h1>
        <h1>
          watts/kg:{" "}
          {distance
            ? trim(wattsPerKilo(time, weightInKilograms, distance))
            : "Enter a distance"}
        </h1>
      </body>
      <footer>
        <a href="http://eodg.atm.ox.ac.uk/user/dudhia/rowing/physics/weight.html#section8">
          I want to read more about boat physics
        </a>
      </footer>
    </Container>
  );
}

export default App;
